<script setup lang="ts">
import type { CmsSlot } from '@shopware-pwa/types';
import { getCmsLayoutConfiguration } from '@shopware-pwa/helpers-next';

const props = defineProps<{
    content: CmsSlot;
}>();

const DynamicRender = () => {
    if (!props.content) {
        return;
    }

    const { resolvedComponent, componentName, isResolved } = resolveCmsComponent(props.content);

    if (resolvedComponent) {
        if (!isResolved) return h('div', {}, 'Problem resolving component: ' + componentName);

        const { cssClasses, layoutStyles } = getCmsLayoutConfiguration(props.content);
        return h(resolvedComponent, {
            content: props.content,
            style: layoutStyles,
            class: cssClasses,
        });
    }
    return h('div', {}, 'Loading...');
};
</script>

<template>
    <DynamicRender />
</template>
